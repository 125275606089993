<app-consumer-focused-navbar></app-consumer-focused-navbar>
<img *ngIf="isSmallWindow" src="assets/images/consumer-focused-banner-mobile.png" alt="image">
<div class="relative flex items-center justify-center"
     [ngClass]="{'focused-banner__wrap mh-800-height new-landing-height': !isSmallWindow}">
<!-- <div class="relative flex items-center justify-center focused-banner__wrap mh-800-height new-landing-height"> -->
  <div class="max-w-screen mx-auto z-30 custom-width">
    <div>
      <div class="grid grid-cols-12 gap-4 lg:gap-12 relative">
        <div class="col-span-12 lg:col-span-3">

        </div>

        <div class="col-span-12 lg:col-span-5">
          <ng-container *ngIf="!isSmallWindow; else small_screen_header">
          <!-- <ng-container> -->
            <h1 class="text-semibold text-white text-right fs-bhead text-regular">
              Organize, securely <br>
              store, and easily <br>
              share ALL your <br>
              account and <br>
              asset info.
            </h1>
          </ng-container>
          <ng-template #small_screen_header>
          <!-- <ng-template> -->
            <h1 class="text-darkblue text-semibold fs-bhead text-regular">
              Organize, securely store, and easily share ALL your account and asset info.
            </h1>
          </ng-template>
        </div>

        <div class="col-span-12 md:col-span-12 lg:col-span-4">
          <div class="bg-pale-blue  -top-12 lg:-top-24 lg:max-w-lg xl:max-w-full lg:absolute p-5 lg:p-8 xl:p-10 bg-content-blue">
            <h1
              class="leading-normal text-semibold text-white text-left text-2xl lg:text-3xl box__text text-regular">
              Keep your digital
              legacy organized
              and accessible for
              just {{ monthlyPricing }}/month.</h1>

            <button (click)="menuChange('landing-intro')"
                    class="bg-primary text-white rounded-sm p-3 lg:p-4 text-base lg:text-lg xl:text-xl font-medium uppercase font-sans w-full my-6 lg:my-8 focus:outline-none start-now">
              Start
              Now
            </button>
            <p class="leading-normal text-semibold text-white text-left xl:text-2xl text-regular">NO
              OBLIGATION </p>
            <p class="leading-normal text-white text-left text-lg xl:text-xl font-ave-semibold">
              Cancel within the next
              30 days for full refund.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BEGIN: About Digital Vault Wrapper -->
<div class="bg-primary-lite py-12">
  <!-- <div class="max-w-screen mx-auto"> -->
  <div class="grid grid-cols-12 gap-4 lg:gap-8">
    <div class="col-span-12 overflow-hidden">
      <div class="marquee flex items-center justify-center divide-x-2 divide-primary text-center font-bold leading-6 ">
        <div
          class="px-6 whitespace-nowrap text-xl md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          banking logins
        </div>
        <div
          class="px-6 whitespace-nowrap text-xl md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          credit
          card accounts
        </div>
        <div
          class="px-6 whitespace-nowrap text-xl md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          deeds
        </div>
        <div
          class="px-6 whitespace-nowrap text-xl md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          insurance policies
        </div>
        <div
          class="px-6 whitespace-nowrap text-xl md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          birth certificate
        </div>
        <div
          class="px-6 whitespace-nowrap text-xl md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          brokerage account
        </div>
        <div
          class="px-6 whitespace-nowrap text-xl md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          facebook login
        </div>
        <div
          class="px-6 whitespace-nowrap text-xl md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          mortgage
        </div>
        <div
          class="px-6 whitespace-nowrap text-xl md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          photo
          gallery
        </div>
        <div
          class="px-6 whitespace-nowrap text-xl md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          banking logins
        </div>
        <div
          class="px-6 whitespace-nowrap text-xl md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          credit
          card accounts
        </div>
        <div
          class="px-6 whitespace-nowrap text-xl md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          deeds
        </div>
        <div
          class="px-6 whitespace-nowrap text-xl md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          insurance policies
        </div>
        <div
          class="px-6 whitespace-nowrap text-xl md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          birth certificate
        </div>
        <div
          class="px-6 whitespace-nowrap text-xl md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          brokerage account
        </div>
        <div
          class="px-6 whitespace-nowrap text-xl md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          facebook login
        </div>
        <div
          class="px-6 whitespace-nowrap text-xl md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          mortgage
        </div>
        <div
          class="px-6 whitespace-nowrap text-xl md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          photo
          gallery
        </div>


      </div>
    </div>
  </div>
  <!-- </div> -->
</div>
<!-- END: About Digital Vault Wrapper -->

<!-- BEGIN: Legacy Now Platform Wrapper -->
<div class="section section-even">
  <div class="max-w-screen mx-auto">
    <div class="grid grid-cols-12 gap-6 lg:gap-8">
      <div class="col-span-12 space-y-4">
        <p class="text-lg font-ave-medium text-black leading-normal text-left">
          As we move through life, we accumulate assets, accounts, and other information. The amount
          of information is almost never ending, and increases all the time. Keeping track of it all
          becomes more challenging with each passing day, especially since so much is now digitized.
          Do you have easy access to all this critical information? Do your loved ones have access if you suddenly pass away? If not, it’s time to organize your legacy, now.
        </p>
        <p class="text-lg font-ave-medium text-black text-left"> The LegacyNOW platform helps you organize,
          securely store, and eventually share:</p>
        <ul class="max-w-screen m-auto list-circle leading-normal">
          <li class="text-lg font-ave-medium text-black leading-loose text-left">Account information – banks, investment
            accounts, credit cards, etc.
          </li>
          <li class="text-lg font-ave-medium text-black leading-loose text-left">Family documents – birth certificates,
            trusts, passports, insurance policies, etc.
          </li>
          <li class="text-lg font-ave-medium text-black leading-loose text-left">Real estate documents</li>
          <li class="text-lg font-ave-medium text-black leading-loose text-left">Social media accounts</li>
          <li class="text-lg font-ave-medium text-black leading-loose text-left">Medical records</li>
          <li class="text-lg font-ave-medium text-black leading-loose text-left">Additional items or documents that are
            important to you that you’d like to preserve
            and pass on.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- END: Legacy Now Platform Wrapper -->

<!-- BEGIN: Why Choose Wrapper-->
<div class="section why-choose-legacynow__wrap bg-pale-blue">
  <div class="max-w-screen mx-auto">
    <div class="grid grid-cols-12 gap-4 lg:mb-6">
      <div class="col-span-12">
        <h2 class="text-white font-ave-semibold fs-title pb-8 text-center">
          Why LegacyNOW?
        </h2>
      </div>
    </div>

    <div class="grid grid-cols-12 items-start divide-y-2 lg:divide-y-0 lg:divide-x-2 divide-primary">
      <div class="col-span-12 lg:col-span-4 flex flex-col h-full p-4 xl:py-0 md:p-6 lg:p-8 lg:pl-0">
        <div class="flex flex-col sm:flex-row gap-4 md:gap-6 items-center lg:items-start">
          <img src="assets/images/focused/1.svg" class="w-12 h-12 sm:h-14 sm:w-14 lg:w-16 lg:h-16" alt="Legacy-now">
          <h4 class="font-ave-semibold text-white text-xl xl:text-2xl">Step by Step,
            Guided Organization.</h4>
        </div>
        <p class="font-ave-regular text-white text-base xl:text-lg pt-4 md:pt-6">
          When you first access your personal Digital
          Vault in LegacyNOW, it guides you through
          all possible asset categories so you can be
          sure nothing is missed. Login and update
          at any time.
        </p>
      </div>
      <div class="col-span-12 lg:col-span-4 flex flex-col h-full p-4 xl:py-0 md:p-6 lg:p-8">
        <div class="flex flex-col sm:flex-row gap-4 md:gap-6 items-center lg:items-start">
          <img src="assets/images/focused/2.svg" class="w-12 h-12 sm:h-14 sm:w-14 lg:w-16 lg:h-16" alt="Legacy-now">
          <h4 class="font-ave-semibold text-white text-xl xl:text-2xl">Secure, Universally Accessible.</h4>
        </div>
        <p class="font-ave-regular text-white text-base xl:text-lg pt-4 md:pt-6">
          LegacyNOW is built to modern security standards on the very latest bank-level encryption technology to make sure your vital information is accessible to only you and those you designate. But as an online service, it's easily accessible worldwide.
        </p>
      </div>
      <div class="col-span-12 lg:col-span-4 flex flex-col h-full p-4 xl:py-0 md:p-6 lg:p-8 lg:pr-0">
        <div class="flex flex-col sm:flex-row gap-4 md:gap-6 items-center lg:items-start">
          <img src="assets/images/focused/3.svg" class="w-12 h-12 sm:h-14 sm:w-14 lg:w-16 lg:h-16" alt="Legacy-now">
          <h4 class="font-ave-semibold text-white text-xl xl:text-2xl">A Clear Path Forward.</h4>
        </div>
        <p class="font-ave-regular text-white text-base xl:text-lg pt-4 md:pt-6">
          The LegacyNOW system requires that you designate a Digital Vault Custodian to alert us of your passing so that we can immediately transfer your Digital Vault to your chosen Executor. That way, you can rest assured your digital legacy will be in good hands.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- END: Why Choose Wrapper-->

<!-- BEGIN: Start Now Wrapper -->
<div class="section section-even">
  <div class="max-w-screen mx-auto">
    <div class="grid grid-cols-12 gap-4 md:gap-6 lg:gap-8">
      <div class="col-span-12">
        <h2 class="text-pale-blue font-ave-bold text-xl sm:text-2xl lg:text-3xl mb-3">
          It All Adds Up to Peace of Mind
        </h2>
        <p class="text-base sm:text-xl leading-normal font-ave-medium text-black text-left">
          An organized digital estate is not just a future blessing for your loved ones, but an immediate
          help to you now as a go-to resource for all your account and asset information.
        </p>
      </div>
      <div class="col-span-12">
        <div class="grid grid-cols-3">
          <img src="assets/images/focused/family.svg" class="w-full" alt="Legacy-now">
          <img src="assets/images/focused/tree.png" class="w-full" alt="Legacy-now">
          <img src="assets/images/focused/hands.svg" class="w-full" alt="Legacy-now">
        </div>
      </div>
      <div class="col-span-12">
        <h2 class="text-pale-blue font-ave-bold text-xl sm:text-2xl lg:text-3xl mb-3">
          Start Filling Your Digital Vault Now with No Obligation
        </h2>
        <p class="text-base sm:text-xl leading-normal font-ave-medium text-black text-left">
          The peace of mind you get with LegacyNOW costs just {{ monthlyPricing }}/month. If the system does not
          meet your needs or expectations within the next 30 days, feel free to cancel it at any time.
          We will gladly refund your money and verify the deletion of all your personal information.
        </p>
      </div>
      <div class="col-span-12 mx-auto">
        <button (click)="menuChange('landing-intro')"
                class="bg-primary text-white rounded-sm p-3 lg:p-5 px-8 lg:px-16 mt-8 text-base lg:text-lg xl:text-xl font-medium uppercase font-sans w-auto focus:outline-none">
          Start
          Now
        </button>
      </div>

    </div>
  </div>
</div>
<!-- END: Start Now Wrapper -->

<app-consumer-focused-footer></app-consumer-focused-footer>