<main class="relative">
  <!-- BEGIN: Navbar Wrapper -->
  <app-consumer-landing-navbar-new></app-consumer-landing-navbar-new>
  <!-- END: Navbar Wrapper -->

  <!-- BEGIN: Banner Wrapper -->
  <div class="relative flex flex-col items-center justify-center mh-600-height lg:mh-700-height hero-ss overflow-hidden">
    <div class="container mx-auto z-30">
      <div class="flex items-center">
        <div class="grid grid-cols-12 gap-4 xl:gap-12">
          <div class="col-span-12 md:col-span-7 lg:col-span-6 xl:col-span-5">
            <div class="">
            <h1 class="home-banner__head font-ave-black text-4xl lg:text-5xl text-darkblue">
              Easily secure your digital legacy today.
            </h1>
            <p class="home-banner__content text-xl lg:text-2xl mt-8 mb-8 lg:mb-12 lg:mt-12 font-ave-medium">
              LegacyNOW protects your most valuable information, documents, and other materials, and leaves your loved ones a road map of your estate when they need it the most.
            </p>
            <div class="btn-wrap flex items-center flex-wrap gap-3 md:gap-6 lg:gap-8 lg:mb-12">
              <button (click)="menuChange('educational-intro')" *ngIf="showGetStarted"
                class="ld-btn whitespace-nowrap mr-2 sm:mr-3 text-base md:text-lg flex items-center justify-center text-white font-ave-semibold">
                Get Started
              </button>
              <button (click)="onClickLearnMore()"
                class="ld-btn whitespace-nowrap text-base md:text-lg flex items-center justify-center text-white font-ave-semibold">
                Learn More
              </button>

            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <img class="mt-12 md:mt-0 md:absolute banner__bg z-10 md:object-cover md:w-full md:h-full"
        src="assets/images/home-page/hero-bg.jpeg" alt="">
  </div>
  <!-- END: Banner Wrapper -->

  <!-- BEGIN: Introduce Legacy Wrapper -->
  <div id="introduceLegacy" class="section introduce-legacy-ss">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 items-start gap-0 md:gap-6 xl:gap-12">
        <div class="col-span-12 lg:col-span-6 mb-8 lg:mb-0">
          <div class="grid-flow-row grid gap-6 lg:gap-8">
            <a (click)="openModal()" (keydown)="onKeyDownOnSiteGuide($event)"
                 tabindex="0" class="cursor-pointer relative open-site-guid-modal">
              <img class="w-full bg-img" src="assets/images/home-page/introduce-video-thumb.png" alt="">
              <img class="yt-btn-img" src="assets/images/home-page/youtube3.png" alt="">
            </a>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-6 flex flex-col justify-center h-full">
          <h2 class="font-ave-bold text-2xl md:text-3xl xl:text-4xl leading-snug text-darkblue pb-6">Introducing the Estate Data Management Platform.</h2>
          <p class="font-ave-medium text-lg md:text-xl xl:text-2xl leading-snug">Includes data protection, secure document storage, privacy management, and legacy planning. Allows you to grant access to your personal estate information to those you select when you pass. It’s a thoughtful gift to the loved ones you entrust with the information needed to settle your estate.</p>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Introduce Legacy Vault Wrapper -->

  <!-- BEGIN: Why Legacy Wrapper -->
  <div class="section why-legacy-ss bg-ld-blue">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 gap-4 lg:gap-12">
        <div class="col-span-12 flex flex-col justify-center lg:col-span-7 md:pr-3 md:pr-0 pt-6 lg:pt-0 order-1 lg:order-0">
          <h2 class="font-ave-black text-white text-center text-2xl md:text-3xl pb-6">
            Why LegacyNOW?
          </h2>
          <p class="font-ave-medium text-white text-left text-lg md:text-xl xl:text-2xl mb-4">
            Do you have easy access to all your critical asset and account information? Do your loved ones have access if you suddenly pass? Now is the time to organize your legacy.
          </p>
          <ul class="why-legacy-lst md:pr-8">
            <li class="open font-ave-medium text-white text-left text-lg md:text-xl xl:text-2xl mb-4">
              <b class="font-ave-black">Open a LegacyNOW</b> account in minutes.
            </li>
            <li class="fill font-ave-medium text-white text-left text-lg md:text-xl xl:text-2xl mb-4">
              <b class="font-ave-black">Fill LegacyNOW’s digital vault</b> with asset info from banks, utilities, social media accounts, and whatever you want to store and share.
            </li>
            <li class="encrypted font-ave-medium text-white text-left text-lg md:text-xl xl:text-2xl mb-4">
              <b class="font-ave-black">Your info is encrypted</b> so only you
              and those you share it with can see it<b class="font-ave-black">—we can never see your personal data.</b>
            </li>
            <li class="cancel font-ave-medium text-white text-left text-lg md:text-xl xl:text-2xl">
              Cancel within 30 days for a full refund.
              <a class="font-ave-black-oblique underline cursor-pointer" tabindex="0" *ngIf="showGetStarted"
                (click)="menuChange('educational-intro')">START NOW</a>
            </li>
          </ul>
        </div>

        <div class="col-span-12 md:col-span-12 lg:col-span-5 order-0 lg:order-1">
          <img class="ml-auto md:mr-auto"
            src="assets/images/home-page/computer_safe_screen.png"
            alt=""/>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Why Legacy Wrapper -->

  <!-- BEGIN: Access at your fingertips Wrapper -->
  <div class="section your-fingertips-ss">
    <div class="container mx-auto">
      <div class="grid grid-cols-12">
        <div class="col-span-12 lg:col-span-5 flex flex-col justify-start h-full pt-6 lg:pt-0 order-1 lg:order-0">
          <img src="assets/images/home-page/woman_in_orange_sweater.jpeg" class="img-border" alt="Access at your fingertips"/>
          <!-- <button (click)="menuChange('educational-intro')" class="ld-btn blue-btn flex items-center justify-center text-white font-ave-semibold">
            <img src="assets/images/home-page/video-icon.png" alt="Video Icon"/>
            LEARN MORE
          </button> -->
        </div>
        <div class="col-span-12 lg:col-span-7 flex flex-col justify-start h-full pl-0 lg:pl-12 order-0 lg:order-1">
          <div class="flex gap-4 md:gap-4 items-center">
            <img
              src="assets/images/home-page/access-pencil_b.png"
              class="w-12 sm:w-14"
              alt="Access at your fingertips"
            />
            <h2 class="font-ave-semibold text-darkblue text-2xl md:text-3xl xl:text-4xl">
              Access at Your Fingertips
            </h2>
          </div>

          <p class="font-ave-regular text-lg md:text-xl xl:text-2xl mt-5 xl:mt-6">
            As life changes, so does your information. LegacyNOW’s cloud-based platform provides you with the flexibility to update and change your data effortlessly, ensuring it’s always current and accessible.
          </p>
          <p class="font-ave-regular text-lg md:text-xl xl:text-2xl mt-4 xl:mt-5">
            With LegacyNOW, you can:
          </p>
          <div class="flex flex-col sm:flex-row mt-5 sm:divide-x-2 divide-primary">
            <div class="col-span-12 lg:col-span-4 px-0 sm:px-6 xl:px-12 text-left sm:text-center font-ave-bold text-darkblue text-lg md:text-xl xl:text-2xl">
              Safeguard important documents
            </div>
            <div class="col-span-12 lg:col-span-4 py-3 sm:py-0 px-0 sm:px-6 xl:px-12 text-left sm:text-center font-ave-bold text-darkblue text-lg md:text-xl xl:text-2xl">
              Organize personal records
            </div>
            <div class="col-span-12 lg:col-span-4 px-0 sm:px-6 xl:px-12 text-left sm:text-center font-ave-bold text-darkblue text-lg md:text-xl xl:text-2xl">
              Plan for the future with ease
            </div>
          </div>
          <p class="font-ave-regular text-lg md:text-xl xl:text-2xl  mt-4 xl:mt-5">
            Our user-friendly interface makes it easy to navigate and manage your information, giving you peace of mind knowing your data is securely stored and readily available.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Access at your fingertips Wrapper -->

  <!-- BEGIN: You’re in Control Wrapper -->
  <div class="section you-are-in-control-ss">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 lg:divide-y-0 lg:divide-x-2 divide-primary">
        <div class="col-span-12 lg:col-span-6 flex flex-col justify-center h-full px-0 md:px-12">
          <div class="flex gap-4 md:gap-6 items-center">
            <img src="assets/images/home-page/you-are-in-control.png"  alt="You’re in Control" />
            <h2 class="font-ave-semibold text-lightgreen text-2xl md:text-3xl xl:text-4xl">
              You’re in Control
            </h2>
          </div>
          <ul class="dot-style mt-6">
            <li class="font-ave-regular text-lg md:text-xl xl:text-2xl text-white">
              Enjoy peace of mind knowing your data is private and protected.
            </li>
            <li class="font-ave-regular text-lg md:text-xl xl:text-2xl text-white mt-4">
              Securely store and manage vital documents and personal records.
            </li>
            <li class="font-ave-regular text-lg md:text-xl xl:text-2xl text-white mt-4">
              Complete control over who accesses your information and when.
            </li>
          </ul>
        </div>
        <div class="col-span-12 lg:col-span-6 flex flex-col justify-center h-full px-0 md:px-16 pt-6 lg:pt-0 ">
          <img src="assets/images/home-page/security_screens_is.png" alt="You’re in Control"/>
          <!-- <button (click)="menuChange('educational-intro')" class="ld-btn blue-btn flex items-center justify-center text-white font-ave-semibold">
            <img src="assets/images/home-page/video-icon.png" alt="Video Icon"/>
            LEARN MORE
          </button> -->
        </div>
      </div>
    </div>
  </div>
  <!-- END: You’re in Control Wrapper -->

  <!-- BEGIN: What is Legacy Planning Wrapper -->
  <div class="section wi-legacy-planing-ss">
    <div class="container mx-auto">
      <div class="grid grid-cols-12">
        <div class="col-span-12 lg:col-span-5 flex flex-col justify-start h-full pt-6 lg:pt-0 order-1 lg:order-0">
          <img src="assets/images/home-page/remembering_a_life.png" class="img-border" alt="What is Legacy Planning"/>
          <!-- <button (click)="menuChange('educational-intro')" class="ld-btn blue-btn flex items-center justify-center text-white font-ave-semibold">
            <img src="assets/images/home-page/video-icon.png" alt="Video Icon"/>
            LEARN MORE
          </button> -->
        </div>
        <div class="col-span-12 lg:col-span-7 flex flex-col justify-start h-full pl-0 lg:pl-12 order-0 lg:order-1">
          <div class="flex gap-4 md:gap-6 items-center">
            <img
              src="assets/images/home-page/planning_clipboard_b.png"
              class="w-12 sm:w-14"
              alt="What is Legacy Planning"
            />
            <h2 class="font-ave-semibold text-darkblue text-2xl md:text-3xl xl:text-4xl">
              What is Legacy Planning
            </h2>
          </div>

          <p class="font-ave-regular text-lg md:text-xl xl:text-2xl mt-3">
            Legacy Planning is about more than just sharing standard end-of-life documents. We believe your life's story deserves to be preserved and shared in a meaningful way.
          </p>
          <ul class="dot-style mt-3">
            <li class="font-ave-regular text-lg md:text-xl xl:text-2xl">
              Create a personalized online archive accessible to loved ones.
            </li>
            <li class="font-ave-regular text-lg md:text-xl xl:text-2xl mt-3">
              Store and share photo galleries and other personal documents.
            </li>
            <li class="font-ave-regular text-lg md:text-xl xl:text-2xl mt-3">
              Capture your life's accomplishments and wishes.
            </li>
            <li class="font-ave-regular text-lg md:text-xl xl:text-2xl mt-3">
              Ensure your loved ones, who may have designated roles based on your wishes, have everything they need.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- END: What is Legacy Planning Wrapper -->

  <!-- BEGIN: Protect Your Important Documents Wrapper -->
  <div class="section protect-your-documents-ss">
    <div class="container mx-auto">
      <div class="grid grid-cols-12">
        <div class="col-span-12 lg:col-span-7 flex flex-col justify-center h-full py-12 sm:py-16 pr-0 lg:pr-12">
          <div class="flex gap-4 md:gap-6 items-center">
            <img
              src="assets/images/home-page/protect-icon.png"
              class="w-12 sm:w-14"
              alt="Protect Your Important Documents"
            />
            <h2 class="font-ave-semibold text-lightgreen text-2xl md:text-3xl xl:text-4xl">
              Protect Your Important Documents
            </h2>
          </div>
          <ul class="dot-style pr-0 lg:pr-8">
            <li class="font-ave-regular text-lg md:text-xl xl:text-2xl mt-3">
              Safeguard your vital information from loss or damage.
            </li>
            <li class="font-ave-regular text-lg md:text-xl xl:text-2xl mt-3">
              Securely store insurance policies, personal records, and essential paperwork.
            </li>
            <li class="font-ave-regular text-lg md:text-xl xl:text-2xl mt-3">
              Access your documents anytime, anywhere.
            </li>
            <li class="font-ave-regular text-lg md:text-xl xl:text-2xl mt-3">
              Your info is encrypted so only you and those you share it with can see it—we can never see your personal data.
            </li>
            <li class="font-ave-regular text-lg md:text-xl xl:text-2xl mt-3">
              Enjoy peace of mind knowing your information is protected. <a class="font-ave-black-oblique underline cursor-pointer" tabindex="0" *ngIf="showGetStarted"
              (click)="menuChange('educational-intro')">Create your account today.</a>
            </li>
          </ul>
        </div>
        <div class="col-span-12 lg:col-span-5 flex flex-col justify-center h-full img-col">
          <img src="assets/images/home-page/expansion-final.png"
             alt="Protect Your Important Documents"/>
          <!-- <button (click)="menuChange('educational-intro')" class="ld-btn blue-btn flex items-center justify-center text-white font-ave-semibold">
            <img src="assets/images/home-page/video-icon.png" alt="Video Icon"/>
            LEARN MORE
          </button> -->
        </div>
      </div>
    </div>
  </div>
  <!-- END: Protect Your Important Documents Wrapper -->

  <!-- BEGIN: Protect What Matters Most Wrapper -->
  <div class="section pw-matters-most-ss">
    <div class="container mx-auto">
      <div class="grid grid-cols-12">
        <div class="col-span-12 lg:col-span-5 flex flex-col justify-start h-full pt-6 lg:pt-0 order-1 lg:order-0">
          <img class="max-w-lg mx-auto w-full lg:max-w-none lg:mx-0 lg:w-auto"
               src="assets/images/home-page/Hiking-Couple.png"
               alt="Protect What Matters Most"/>
        </div>
        <div class="col-span-12 lg:col-span-7 flex flex-col justify-center h-full pl-0 lg:pl-12 order-0 lg:order-1">
          <div class="flex gap-4 md:gap-4 items-center">
            <h2 class="font-ave-semibold text-darkblue text-2xl md:text-3xl xl:text-4xl">
              Protect What Matters Most
            </h2>
          </div>
          <p class="font-ave-regular text-lg md:text-xl xl:text-2xl mt-3">
            LegacyNOW safeguards your most vital information, ensuring your loved ones have access when it’s needed most. Our secure digital vault stores and organizes:
          </p>
          <ul class="dot-style mt-3">
            <li class="font-ave-regular text-lg md:text-xl xl:text-2xl">
              Legal documents: wills, trusts, power of attorney
            </li>
            <li class="font-ave-regular text-lg md:text-xl xl:text-2xl mt-3">
              Financial records: bank statements, investment portfolios, tax returns
            </li>
            <li class="font-ave-regular text-lg md:text-xl xl:text-2xl mt-3">
              Personal identification: birth certificates, passports, social security cards
            </li>
            <li class="font-ave-regular text-lg md:text-xl xl:text-2xl mt-3">
              Medical records: health history, insurance policies, advance directives
            </li>
            <li class="font-ave-regular text-lg md:text-xl xl:text-2xl mt-3">
              Digital assets: passwords, online account information, cryptocurrency keys
            </li>
            <li class="font-ave-regular text-lg md:text-xl xl:text-2xl mt-3">
              Sentimental items: family recipes, personal letters, photo collections
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Protect What Matters Most Wrapper -->

  <!-- BEGIN: Testimonial Wrapper-->
  <div class="section testimonial-ss">
    <div class="container mx-auto">
      <div class="testimonial-wrap pt-8 sm:pt-10">
        <div class="test-heading">
          <h2 class="text-center px-4 font-ave-bold text-darkblue text-2xl md:text-3xl xl:text-4xl">
            What Our Customers Say.
          </h2>
        </div>
        <div class="testimonial-box p-8 px-12 sm:px-24 lg:px-0 pb-12 sm:pb-16 flex justify-center h-full">
          <div class="testimonial-content">
            <div class="testimonial-quote relative font-ave-regular text-base sm:text-lg md:text-xl">
              LegacyNOW’s digital vault has given me the peace of mind that I’m doing something that will help my family and friends if I ever do leave unexpectedly.
            </div>
            <div class="flex items-center justify-center absolute test-author">
              <strong class="font-ave-semibold text-lg text-center">- T. Cai</strong>
            </div>
          </div>
        </div>
        <div class="testimonial-box p-8 px-12 sm:px-24 lg:px-0 pb-12 sm:pb-16 flex justify-center h-full">
          <div class="testimonial-content">
            <div class="testimonial-quote relative font-ave-regular text-base sm:text-lg md:text-xl">
              After losing my father, I wish we had something like this. LegacyNOW has made managing my own affairs so much simpler for my children.
            </div>
            <div class="flex items-center justify-center absolute test-author">
              <strong class="font-ave-semibold text-lg text-center">- M. Johnson</strong>
            </div>
          </div>
        </div>
        <div class="testimonial-box p-8 px-12 sm:px-24 lg:px-0 pb-12 sm:pb-16 flex justify-center h-full">
          <div class="testimonial-content">
            <div class="testimonial-quote relative font-ave-regular text-base sm:text-lg md:text-xl">
              The security features really put my mind at ease. I feel confident that my information is safe but accessible to those who’ll need it.
            </div>
            <div class="flex items-center justify-center absolute test-author">
              <strong class="font-ave-semibold text-lg text-center">- R. Patel</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Testimonial Wrapper -->

  <!-- BEGIN: Why Choose LegacyNOW Wrapper-->
  <div class="section wc-legacynow-ss">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 wc-wrap">
        <div class="col-span-12 pt-8 sm:pt-10">
          <h2 class="text-center px-4 font-ave-bold text-white text-2xl md:text-3xl xl:text-4xl">
            Why Choose LegacyNOW?
          </h2>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div class="p-6 px-6 md:p-10 lg:px-16 h-full">
            <ul class="dot-style">
              <li class="font-ave-regular text-white text-lg md:text-xl xl:text-2xl">
                User-friendly interface
              </li>
              <li class="font-ave-regular text-white text-lg md:text-xl xl:text-2xl mt-2">
                Customizable access controls
              </li>
              <li class="font-ave-regular text-white text-lg md:text-xl xl:text-2xl mt-2">
                Regular automatic backups
              </li>
              <li class="font-ave-regular text-white text-lg md:text-xl xl:text-2xl mt-2">
                24/7 customer support
              </li>
              <li class="font-ave-regular text-white text-lg md:text-xl xl:text-2xl mt-2">
                30-day money-back guarantee
              </li>
            </ul>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div class="p-6 px-6 md:p-10 lg:px-16 h-full">
            <p class="font-ave-regular text-white text-lg md:text-xl xl:text-2xl text-center">
              Don’t leave your legacy to chance. Secure your digital future with LegacyNOW today.
            </p>
            <button (click)="menuChange('educational-intro')"
                class="ld-btn blue-btn whitespace-nowrap text-base md:text-lg flex items-center justify-center text-white font-ave-semibold">
                GET STARTED
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Why Choose LegacyNOW? Wrapper -->

  <!-- BEGIN: Footer Wrapper -->
  <app-consumer-landing-footer></app-consumer-landing-footer>
  <!-- END: Footer Wrapper -->
</main>
<app-demo-video-modal [modal_id]="VIDEO_MODAL_ID" [vaultVideo]="VAULT_VIDEO"></app-demo-video-modal>
