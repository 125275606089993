import { Component, HostListener, OnInit } from '@angular/core';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonService } from 'src/app/services/common.service';
import { ConsumerSubscriptionService } from '../../consumer-payment-section/consumer-subscription.service';


@Component({
  selector: 'app-consumer-marketing',
  templateUrl: './consumer-marketing.component.html',
  styleUrls: ['./consumer-marketing.component.css'],
})
/**
 * landing
 */
export class ConsumerMarketingComponent implements OnInit {
  public menuActive: string;
  public isFooterLink: boolean;
  private innerWidth: number;
  public monthlyPricing: string;

  /**
   * constructor
   */
  constructor(
    private subscriptionService: ConsumerSubscriptionService,
    private commonHelper: CommonHelper,
    private slugInterceptorService: SlugInterceptorService,
    private commonService: CommonService) {
  }
  /**
   * loaded initialy
   */
  ngOnInit(): void {
    this.updateInnerWidth();
    this.getPricing();
  }
  /**
   * isSmallWindow
   */
  get isSmallWindow(): boolean {
    return this.innerWidth < 800;
  }
  /**
   * updateInnerWidth
   */
  private updateInnerWidth() {
    this.innerWidth = window.innerWidth;
    console.debug('inner width', this.innerWidth);
  }
  /**
   * windowResizeListener
   */
  @HostListener('window:resize', [])
  windowResizeListener() {
    this.updateInnerWidth();
  }

  /**
   * Retrieves pricing details
   *
   */
  private getPricing() {
    this.subscriptionService.getPricingDetails().subscribe({
      next: (response: any) => {
        if (response?.status) {
          console.log('getSubscriptionDetailsApi', response);
          const packagePrice = {
            monthly: response.data.subscription_price,
          };
          this.monthlyPricing = this.commonHelper.toCurrency(packagePrice.monthly);
        }
      },
    });
  }

  /**
   * Navigates to the registration page.
   */
  public navigateToRegister() {
    this.slugInterceptorService.navigate(['register']);
  }

  /**
   * @param{string}url
   * on menu change
   */
  public menuChange(url?: string) {
    this.menuActive = url;
    if (url === '') {
      void this.slugInterceptorService.navigate(['/']);
    }
    this.isFooterLink = false;

    void this.slugInterceptorService.navigate(url ? [url] : ['/']);
    this.commonService.setActiveNavbarTrigger(this.menuActive);
  }
}
