<app-common-modal [id]="modal_id" class="app-common-modal" (commonModalEmitter)="modalListener($event)">
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg" alt=""
         class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer"
         (click)="closeModal()" (keydown.enter)="closeModal()">
    <video *ngIf="vaultVideo && isOpen" #videoElementRef controls autoplay width="100%" height="100%">
      <source [src]="getSafeUrl()" type="video/mp4">
    </video>
  </div>
</app-common-modal>
